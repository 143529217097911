<template>
  <div class="login bg-grey-lightest">
    <div class="container">
      <div class="login_form bg-white">
        <form @submit.prevent="" class="flex flex-col gap-30">
          <div class="top_line text-2xl">
            <div class="logo">
              <img v-if="logo_url" :src="logo_url" alt="logo">
              <img v-else src="@/assets/images/logo.png" alt="logo">
            </div>
            <div class="title">
              Login
            </div>
          </div>
          <div class="body flex gap-15 flex-col">
            <div class="input_group">
              <input
                  v-model="auth_data.username"
                  type="text"
                  id="username"
                  placeholder="Username or email"
              />
              <div  v-if="errors.username.length > 0">
                <p class="error">
                  {{ errors.username[0] }}
                </p>
              </div>
            </div>
            <div class="input_group">
              <input
                  v-model="auth_data.password"
                  type="text"
                  id="password_digest"
                  placeholder="Password"
              />
              <div  v-if="errors.password.length > 0">
                <p class="error">
                  {{ errors.password[0] }}
                </p>
              </div>
            </div>
          </div>
          <div class="edit_profile_actions flex-row flex justify-between items-center gap-15">
            <button class="btn w-1/2 green-white"
                    @click="processLogin"
            >
              Log in
            </button>
            <router-link :to="{name: 'ForgotPassword'}" class="btn w-1/2 forgot_pass bold-green text-right">
              Forgot password
            </router-link>
          </div>
          <div class="register pb-15">
            Not registered yet?
            <router-link
                :to="{ name: 'Register'}"
                class="bold-green"
            >
              &nbsp;Click here&nbsp;
            </router-link>
            to register now
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {infoMessage} from "../../services/messages";
export default {
  name: "Login",
  data() {
    return {
      logo_url: null,
      auth_data: {
        username: '',
        password: '',
      },
      errors: {
        username: [],
        password: [],
      }
    }
  },
  methods: {
    ...mapMutations([
      'loginUser'
    ]),
    logoUrl() {
      this.logo_url = `${this.$http.baseUrl()}/` + JSON.parse(localStorage.getItem('supportData')).logo_url || null
    },
    errorsHandler(errors) {
      const j = this.errors;
      errors.forEach(function(error){
        if (error.split(' ')[0]) {
          j[error.split(' ')[0]].push(error);
        }
      })
      console.log(j)
    },
    async processLogin() {
      try {
        for (let error in this.errors) {
          if (error.length > 0 && error != null) {
            this.errors[error] = []
          }
        }
        const http = this.$http;
        const {username, password} = this.auth_data;
        const request_data = {username, password};
        let errors = null;
        const response = await http.post(`${http.apiUrl()}/auth/login`, request_data).catch(err => {
          errors = err.response?.data?.message;
          if (typeof errors === 'string') {
            return infoMessage(errors || 'Oops. Something went wrong...', 'error')
          }
          return this.errorsHandler(errors);
        });
        if (errors){
          return
        }
        const {user, access_token} = response?.data || {};

        if (user && access_token) {
         await this.loginUser({user, access_token});
          try {
            await this.$router.replace('/');
            infoMessage('Successfully logged in', 'success')
            window.location.reload()
          } catch (e) {
            infoMessage(e || 'Oops. Something went wrong...', 'error')
          }
        }
      } catch (e) {
        console.log(e?.response?.data?.error?.message || 'Something went wrong...');
      }
    }
  },
  async mounted() {
      await this.logoUrl();
  }
}
</script>

<style lang="scss" scoped>

</style>

